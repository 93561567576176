import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import invoice from './invoiceSlice'

const reducer = combineReducers({
  common,
  invoice,
})

export default reducer
