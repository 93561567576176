import {
  createListenerMiddleware,
  createSlice,
  current,
} from '@reduxjs/toolkit'

const initialValue = {
  oldTableData: [],
  newTableData: [],
  dataProductCategory: [],
  dataProductType: [],
  price: {
    total_price: 0,
    pph: 0,
    adjustments: 0,
  },
  adjustments: [],
  isPphChecked: false,
  accountR: {
    overweight_price: 0,
    account_receivable: '',
  },
}

export const invoiceSlice = createSlice({
  name: 'invoice',
  initialState: {
    oldTableData: [],
    newTableData: [],
    dataProductCategory: [],
    dataProductType: [],
    accountR: {
      overweight_price: 0,
      account_receivable: '',
    },
    price: {
      total_price: 0,
      pph: 0,
      adjustments: 0,
    },
    adjustments: [],
    isPphChecked: false,
  },
  reducers: {
    onAddedTableData: (state, action) => {
      console.log(action)
      state.newTableData = action.payload
    },
    onEditAdjustments: (state, action) => {
      var currState = current(state)
      var adj = action.payload.adjustment
      var index = action.payload.index
      var newAdjs = [...currState.adjustments, ...adj[index]]
      state.adjustments = newAdjs

      state.price = {
        ...currState.price,
        adjustments: action.payload.reduce(
          (sum, item) => sum + parseInt(item.price),
          0
        ),
      }
    },
    onAdjustments: (state, action) => {
      state.adjustments = action.payload
      var currState = current(state)
      console.log(action.payload)
      state.price = {
        ...currState.price,
        adjustments: action.payload.reduce(
          (sum, item) => sum + parseInt(item.price),
          0
        ),
      }
    },
    onPphChecked: (state, action) => {
      console.log(action)
      state.isPphChecked = action.payload
      var currState = current(state)

      var newTable = [
        ...currState.oldTableData.map((receive, index) => {
          const calculatePrice = () => {
            var priceList = currState?.dataProductCategory?.find(
              (value, index) => {
                return value.value === receive?.product_category_id
              }
            ) ?? {
              price: 0,
            }
            var selectedPriceList = priceList?.productType?.find(
              (value, index) => {
                return value.id === receive?.product_type_id
              }
            )
            var volume = receive?.round_volume ? 0.1 : receive?.old_volume
            var customerCost = receive?.qc * volume * selectedPriceList?.price
            return customerCost ?? 0
          }

          const calculateOverweightPrice = () => {
            var overweightPrice = currState?.accountR?.overweight_price
            var finalOverweightPrice = overweightPrice * receive.overweight

            return finalOverweightPrice ?? 0
          }

          const calculateTotalPrice = () => {
            var totalPrice = calculatePrice()
            return action.payload ? totalPrice / 0.98 : totalPrice
          }

          var obj = {
            ...receive,
            volume: receive?.round_volume === 1 ? 0.1 : receive?.old_volume,
            overweight_price: 0,
            customer_cost: calculatePrice(),
            total_price: calculateTotalPrice(),
          }
          console.log(obj)
          return obj
        }),
      ]
      state.oldTableData = newTable

      var expectedPph = newTable.reduce((prev, curr) => {
        var pphValue = action.payload ? curr.total_price : 0
        return prev + pphValue
      }, 0)

      var tempTotalPrice = newTable.reduce((prev, curr) => {
        return prev + curr.total_price
      }, 0)

      //price
      state.price = {
        total_price: action.payload ? tempTotalPrice * 0.98 : tempTotalPrice,
        pph: expectedPph,
        adjustments: [],
      }
    },
    onDetailInvoiceTableData: (state, action) => {
      console.log(action)
      var currState = current(state)
      var oldTableData = action.payload.oldTableData
      state.isPphChecked = action.payload.pph
      state.dataProductCategory = action.payload.dataProductCategory
      state.dataProductType = action.payload.dataProductType
      state.accountR = action.payload.accountR
      var newTable = [
        ...oldTableData.map((receive, index) => {
          // const calculateOverweightPrice = () => {
          //   var overweightPrice = state.accountR.overweight_price
          //   var finalOverweightPrice = overweightPrice * receive.overweight

          //   return finalOverweightPrice
          // }

          const calculateTotalPrice = () => {
            var totalPrice = receive.total_price
            return totalPrice
          }

          const setCustomerCost = () => {
            return action.payload.pph
              ? Math.round(receive.total_price * 0.98)
              : receive.total_price
          }

          const produkDetail = () => {
            var kategori_produk = action?.payload?.dataProductCategory.find(
              (value) => {
                return value.value === receive.product_category_id
              }
            )

            var tipe_produk = action?.payload?.dataProductType.find((value) => {
              console.log(value)
              console.log(receive.product_type_id)
              return value.value === receive.product_type_id
            })

            return {
              kategori_produk: kategori_produk?.label,
              tipe_produk: tipe_produk?.label ?? 'UNKNOWN',
            }
          }

          console.log(calculateTotalPrice())

          var selectedProduct = produkDetail()
          var selectedProductCategory = selectedProduct.kategori_produk
          var selectedTypeCategory = selectedProduct.tipe_produk

          var newProcessedData = {
            ...receive,
            overweight_price: 0,
            volume: receive?.round_volume === 1 ? 0.1 : receive?.old_volume,
            kategori_produk: selectedProductCategory,
            tipe_produk: selectedTypeCategory,
            customer_cost: setCustomerCost(),
            total_price: calculateTotalPrice(),
          }

          return newProcessedData
        }),
      ]

      state.oldTableData = newTable

      var expectedPph = newTable.reduce((prev, curr) => {
        var pphValue = action.payload.pph ? curr.total_price : 0
        return prev + pphValue
      }, 0)

      var tempTotalPrice = newTable.reduce((prev, curr) => {
        return action.payload.pph
          ? prev + Math.round(curr.total_price * 0.98)
          : prev + curr.total_price
      }, 0)

      //price
      state.price = {
        total_price: tempTotalPrice,
        pph: expectedPph,
        adjustments: [],
      }
    },
    onAddedBeginTableData: (state, action) => {
      console.log(action.payload)
      var currState = current(state)
      var oldTableData = action.payload.oldTableData
      state.dataProductCategory = action.payload.dataProductCategory
      state.accountR = action.payload.accountR
      var newTable = [
        ...oldTableData.map((receive, index) => {
          //untuk edit mode, hanya fetch di inv maka kategori dan tipe produk tidak muncul label, maka ambil dari dataProductCategory yg terdapat pada pricelist
          var selectedProductCategory =
            action?.payload?.dataProductCategory.find((value, index) => {
              return value.value === receive?.product_category_id
            })

          console.log(
            'selectedProductCat' + JSON.stringify(selectedProductCategory)
          )

          const calculatePrice = () => {
            var priceList = action?.payload?.dataProductCategory.find(
              (value, index) => {
                return value.value === receive?.product_category_id
              }
            ) ?? {
              price: 0,
            }

            console.log(priceList)
            var selectedPriceList = priceList?.productType?.find(
              (value, index) => {
                return value.id === receive?.product_type_id
              }
            )
            var volume = receive?.round_volume ? 0.1 : receive?.old_volume
            var customerCost = receive?.qc * volume * selectedPriceList?.price
            return customerCost ?? 0
          }

          const calculateOverweightPrice = () => {
            var overweightPrice = action?.payload?.accountR.overweight_price
            var finalOverweightPrice = overweightPrice * receive.overweight

            return finalOverweightPrice ?? 0
          }

          const calculateTotalPrice = () => {
            var totalPrice = calculatePrice()
            return currState.isPphChecked ? totalPrice / 0.98 : totalPrice
          }

          const getKategoriProduk = () => {
            return selectedProductCategory?.label
          }

          const getTipeProduk = () => {
            return selectedProductCategory?.productType?.find((item, index) => {
              console.log(item)
              return item?.id === receive?.product_type_id
            })?.name
          }

          var obj = {
            ...receive,
            volume: receive?.round_volume === 1 ? 0.1 : receive?.old_volume,
            overweight_price: 0,
            kategori_produk: getKategoriProduk(),
            tipe_produk: getTipeProduk(),
            customer_cost: calculatePrice(),
            total_price: calculateTotalPrice(),
          }
          console.log(obj)
          return obj
        }),
      ]
      state.oldTableData = newTable

      var expectedPph = newTable.reduce((prev, curr) => {
        var pphValue = currState.isPphChecked ? curr.total_price : 0
        return prev + pphValue
      }, 0)

      var tempTotalPrice = newTable.reduce((prev, curr) => {
        return prev + curr.total_price
      }, 0)

      //price
      state.price = {
        total_price: state.isPphChecked
          ? tempTotalPrice * 0.98
          : tempTotalPrice,
        pph: expectedPph,
        adjustments: [],
      }
    },
    onResetTableData: (state, action) => {
      return initialValue
    },
    onAdjustmentAdded: (state, action) => {
      state.price = {
        ...state.price,
        adjustments: action.payload.adjustments,
      }
    },
    onEditedTableData: (state, action) => {
      var currState = current(state)
      var receive = action.payload.receive
      var index = action.payload.index
      var oldTableData = action.payload.oldTableData

      var newTable = [
        ...oldTableData.map((receive, index) => {
          var priceList = currState?.dataProductCategory.find(
            (value, index) => {
              return value.value === receive?.product_category_id
            }
          ) ?? {
            label: '',
            productType: [],
            value: '',
          }

          var selectedPriceList = priceList?.productType?.find(
            (value, index) => {
              return value.id === receive?.product_type_id
            }
          )

          const calculatePrice = () => {
            var volume = receive?.round_volume ? 0.1 : receive?.old_volume

            var customerCost = receive?.qc * volume * selectedPriceList?.price
            return customerCost ?? 0
          }

          const calculateOverweightPrice = () => {
            var overweightPrice = currState.accountR.overweight_price
            var finalOverweightPrice = overweightPrice * receive?.overweight

            return finalOverweightPrice ?? 0
          }

          const calculateTotalPrice = () => {
            var totalPrice = calculatePrice()
            return currState.isPphChecked ? totalPrice / 0.98 : totalPrice
          }

          const getKategoriProduk = () => {
            return priceList?.label
          }

          const getTipeProduk = () => {
            return selectedPriceList?.name
          }

          console.log(calculatePrice())

          var obj = {
            ...receive,
            volume: receive?.round_volume === 1 ? 0.1 : receive?.old_volume,
            overweight_price: 0,
            customer_cost: calculatePrice(),
            total_price: calculateTotalPrice(),
            kategori_produk: getKategoriProduk(),
            tipe_produk: getTipeProduk(),
          }
          console.log(obj)
          return obj
        }),
      ]
      state.oldTableData = newTable

      var expectedPph = newTable.reduce((prev, curr) => {
        var pphValue = currState.isPphChecked ? curr.total_price : 0
        return prev + pphValue
      }, 0)

      var tempTotalPrice = newTable.reduce((prev, curr) => {
        return prev + curr?.total_price
      }, 0)

      //price
      state.price = {
        ...currState.price,
        total_price: currState.isPphChecked
          ? tempTotalPrice * 0.98
          : tempTotalPrice,
        pph: expectedPph,
      }
    },
  },
})

export const {
  onAddedTableData,
  onResetTableData,
  onPphChecked,
  onAddedBeginTableData,
  onEditedTableData,
  onDetailInvoiceTableData,
  onAdjustments,
} = invoiceSlice.actions

export const invoiceMiddleware = createListenerMiddleware()
// invoiceMiddleware.startListening({
//   actionCreator: onAddedBeginTableData,
//   effect: (action, listener) => {

//   }
// })

export default invoiceSlice.reducer
