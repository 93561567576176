import dayjs from 'dayjs'
import { forwardRef, useEffect, useRef, useState } from 'react'
import Button from '../Buttons'
import useControllableState from '../hooks/useControllableState'
import useMergedRef from '../hooks/useMergeRef'
import BasePicker from './BasePicker'
import Calendar from './Calendar'

const DEFAULT_INPUT_FORMAT = 'YYYY-MM-DD' // Adjust the format as per your requirement

const DateTimepicker = forwardRef((props, ref) => {
  const {
    inputFormat,
    defaultOpen,
    defaultValue,
    value,
    onChange,
    onDropdownClose,
    onDropdownOpen,
    closePickerOnChange,
    isUpdate,
    disabledDate,
    ...rest
  } = props

  const dateFormat = inputFormat || DEFAULT_INPUT_FORMAT

  const [dropdownOpened, setDropdownOpened] = useState(defaultOpen)
  const [update, setIsUpdate] = useState(true)
  const inputRef = useRef()

  const [_value, setValue] = useControllableState({
    prop: value,
    defaultProp: defaultValue,
    onChange,
  })

  useEffect(() => {
    setValue(Date.now())
    setInputState(dayjs(Date.now()).format(dateFormat))
  }, [])

  useEffect(() => {
    setIsUpdate(isUpdate)
    console.log(isUpdate)
  }, [isUpdate])

  const [inputState, setInputState] = useState(
    _value instanceof Date ? dayjs(_value).format(dateFormat) : ''
  )

  const closeDropdown = () => {
    setDropdownOpened(false)
    onDropdownClose?.()
  }

  const openDropdown = () => {
    setDropdownOpened(true)
    onDropdownOpen?.()
  }

  useEffect(() => {
    if (value === null) {
      setInputState('')
    } else if (value instanceof Date) {
      setInputState(dayjs(value).format(dateFormat))
    }
  }, [value, dateFormat])

  const handleValueChange = (date) => {
    setValue(date)
    setInputState(dayjs(date).format(dateFormat))
    if (closePickerOnChange) {
      closeDropdown()
    }
  }

  const parseDate = (date) => dayjs(date, dateFormat).toDate()

  const handleClear = () => {
    setValue(Date.now())
    var expectedDate = dayjs(Date.now()).format(dateFormat)
    setInputState(expectedDate)
    inputRef.current?.focus()
    onChange?.(null)
  }

  const handleInputChange = (e) => {
    const date = parseDate(e.target.value)
    if (dayjs(date).isValid()) {
      setValue(date)
      setInputState(e.target.value)
    } else {
      setInputState(e.target.value)
    }
  }

  const mergedRef = useMergedRef(ref, inputRef)

  return update ? (
    <BasePicker
      dropdownOpened={dropdownOpened}
      setDropdownOpened={setDropdownOpened}
      ref={mergedRef}
      onChange={handleInputChange}
      name={props.name}
      inputLabel={inputState}
      clearable={!!_value}
      onClear={handleClear}
      {...rest}
    >
      <Calendar
        value={_value instanceof Date ? _value : null}
        onChange={handleValueChange}
        disableDate={disabledDate ?? null}
        {...rest}
      />
      <div className="flex items-center justify-end text-right gap-4 mt-4">
        <Button size="sm" disabled={!_value} onClick={() => closeDropdown()}>
          {props.okButtonContent || 'OK'}
        </Button>
      </div>
    </BasePicker>
  ) : (
    <div ref={ref}>{inputState}</div>
  )
})

export default DateTimepicker
