const appConfig = {
  apiPrefix: process.env.REACT_APP_API_URL,
  reportPrefix: process.env.REACT_APP_API_REPORT,
  authenticatedEntryPath: '/dashboard',
  unAuthenticatedEntryPath: '/sign-in',
  tourPath: '/',
  locale: 'en',
  enableMock: false,
}

export default appConfig
